import Link from '@scandipwa/scandipwa/src/component/Link';
import { PureComponent } from 'react';

import RenderWhenVisible from 'Component/RenderWhenVisible';
import { ReactElement } from 'Type/Common.type';

import { FooterCheckoutContainerProps } from './FooterCheckout.type';

import './FooterCheckout.style';

/** @namespace Pwa/Component/FooterCheckout/Container */
export class FooterCheckoutContainer extends PureComponent<FooterCheckoutContainerProps> {
    static defaultProps: FooterCheckoutContainerProps = {
        copyright: '',
    };

    renderCopyrightContent(): ReactElement {
        const { copyright } = this.props;

        return (
            <div
              block="FooterCheckout"
              elem="Copyright"
            >
                { copyright }
            </div>
        );
    }

    renderPolicy(): ReactElement {
        return (
            <div
              block="FooterCheckout"
              elem="Link"
            >
                <Link to={ __('Footer.privacyUrl') }>
                    { __('Footer.privacy') }
                </Link>
                <Link to={ __('Footer.cookiesUrl') }>
                    { __('Footer.cookies') }
                </Link>
            </div>
        );
    }

    renderContent(): ReactElement {
        return (
            <div
              mix={ { block: 'FooterCheckout', elem: 'ContentWrapper' } }
            >
                <div block="FooterCheckout" elem="Bottom">
                    { this.renderCopyrightContent() }
                    { this.renderPolicy() }
                </div>
            </div>
        );
    }

    render(): ReactElement {
        return (
            <RenderWhenVisible>
                <footer block="FooterCheckout" aria-label="FooterCheckout">
                    { this.renderContent() }
                </footer>
            </RenderWhenVisible>
        );
    }
}

export default FooterCheckoutContainer;
