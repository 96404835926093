/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/forbid-component-props */
/* eslint-disable react/forbid-elements */
/* eslint-disable max-len */
/* eslint-disable max-lines */
import { BsFillTelephoneFill } from 'react-icons/bs';
import { FaCheck } from 'react-icons/fa';

import Bonifico from 'Component/Bonifico';
import CmsBlock from 'Component/CmsBlock';
import {
    LOGO, SOCIAL,
} from 'Component/Common/Common.config';
import If from 'Component/If/If.component';
import Image from 'Component/Image';
import Link from 'Component/Link';
// import Mastercard from 'Component/Mastercard';
import NewsletterSubscription from 'Component/NewsletterSubscription';
// import PayPal from 'Component/PayPal';
// import Postepay from 'Component/Postepay';
import RenderWhenVisible from 'Component/RenderWhenVisible';
// import Visa from 'Component/Visa';
import {
    FooterComponent as SourceFooterComponent,
} from 'SourceComponent/Footer/Footer.component';
import { ReactElement } from 'Type/Common.type';
import { isSignedIn } from 'Util/Auth/IsSignedIn';
import { noopFn } from 'Util/Common';

import {
    COLUMN_MAP, RENDER_FIFTH_COLUMN, RENDER_FIRST_COLUMN, RENDER_FOURTH_COLUMN, RENDER_NEWSLETTER,
} from './Footer.config';
import { FooterComponentProps, FooterRenderColumn, FooterRenderColumnItem } from './Footer.type';

import './Footer.override.style';

/** @namespace Pwa/Component/Footer/Component */
export class FooterComponent extends SourceFooterComponent {
    static defaultProps: Partial<FooterComponentProps> = {
        copyright: '',
        isVisibleOnMobile: true,
        onItemClick: noopFn,
    };

    renderMap = {
        [RENDER_FIRST_COLUMN]: {
            render: this.renderFirstColumnContent.bind(this),
        },
        [RENDER_FOURTH_COLUMN]: {
            render: this.renderFourthColumnContent.bind(this),
        },
        [RENDER_NEWSLETTER]: {
            render: this.renderNewsletterSubscriptionBlock.bind(this),
        },
        [RENDER_FIFTH_COLUMN]: {
            render: this.renderFifthColumnContent.bind(this),
        },
    };

    renderFirstColumnContent(): ReactElement {
        return (
            <>
                <Image
                  isPlain
                  src={ LOGO }
                  alt="logo"
                  className="logo"
                />
                <div block="Footer" elem="ColumnItem">
                    { __('Footer.column1') }
                </div>
                <div block="onlyDesktop">
                    { this.renderColumn(SOCIAL) }
                </div>
            </>
        );
    }

    renderColumnItemContent(src?: string, title?: string): ReactElement {
        if (!src) {
            return __(title || '');
        }

        return (
            <Image
              mix={ { block: 'Footer', elem: 'ColumnItemImage' } }
              src={ src }
              isPlain
            />
        );
    }

    renderFourthColumnContent(): ReactElement {
        return (
            <>
                <div block="cellphone">
                    <BsFillTelephoneFill />
                    { __('CheckoutInfoBox.phone') }
                </div>
                <div block="contacts">
                    { __('Footer.clientService.one') }
                    <br />
                    { __('Footer.clientService.two') }
                    <a href={ `mailto:${__('Footer.clientService.mail')}` }>{ __('Footer.clientService.mail') }</a>
                </div>
            </>
        );
    }

    renderFifthColumnContent(): ReactElement {
        if (!isSignedIn()) {
            return null;
        }

        return (
            <button block="Button QuickOrder">{ __('Footer.column5') }</button>
        );
    }

    renderCopyrightContent(): ReactElement {
        const { copyright } = this.props;

        return (
            <div
              block="Footer"
              elem="Copyright"
            >
                { copyright }
            </div>
        );
    }

    renderPaymentMethod(): ReactElement {
        return (
            <div
              block="Footer"
              elem="Payment"
            >
                <CmsBlock identifier="footer_bottom_paymentmethods" />
                <p>{ __('Footer.payment') }</p>
                <div
                  block="Footer"
                  elem="Payment"
                >
                    { /* <PayPal />
                    <Visa />
                    <Mastercard />
                    <Postepay /> */ }
                    <Bonifico />
                </div>
            </div>
        );
    }

    renderBottomFooter(): ReactElement {
        return (
            <div
              mix={ { block: 'Footer', elem: 'ContentWrapper' } }
            >
                <div block="Footer" elem="Bottom">
                    { this.renderCopyrightContent() }
                    { this.renderPaymentMethod() }
                </div>
            </div>
        );
    }

    renderContent(): ReactElement {
        const { footer_content: { footer_cms = undefined } = {} } = window.contentConfiguration || {};

        if (footer_cms) {
            return this.renderCmsBlockWrapper();
        }

        return (
            <div block="Footer" elem="Content">
                { this.renderColumns() }
            </div>
        );
    }

    renderColumns(): ReactElement {
        return (
            <div
              mix={ { block: 'Footer', elem: 'Columns' } }
            >
                { COLUMN_MAP.map(this.renderColumn.bind(this)) }
            </div>
        );
    }

    renderColumn(column: FooterRenderColumn, i?: number): ReactElement {
        const {
            title,
            columnActiveKey,
            items,
            isItemsHorizontal,
            mods = {},
        } = column;

        const contentMods = isItemsHorizontal ? { direction: 'horizontal' } : {};

        if (columnActiveKey && !(columnActiveKey in this.props)) {
            return null;
        }

        const isTitle: boolean = Boolean(title);

        return (
            <div block="Footer" elem="Column" mods={ mods } key={ i }>
                <If condition={ isTitle }>
                    <h3
                      block="Footer"
                      elem="ColumnTitle"
                    >
                        { __(title || '') }
                    </h3>
                </If>
                <div
                  block="Footer"
                  elem="ColumnContent"
                  mods={ contentMods }
                >
                    { items.map(this.renderColumnItem.bind(this)) }
                </div>
            </div>
        );
    }

    renderColumnItemLink(
        item: FooterRenderColumnItem,
        i: number,
    ): ReactElement {
        const { onItemClick } = this.props;
        const { href, src, title } = item;

        return (
            <>
                <If condition={ Boolean(href) }>
                    <Link
                      block="Footer"
                      elem="ColumnItem"
                      to={ __(href || '') }
                      mods={ src ? { type: 'image' } : undefined }
                      key={ i }
                      aria-label={ title }
                      onClick={ onItemClick }
                    >
                        { this.renderColumnItemContent(src, title) }
                    </Link>
                </If>
                <If condition={ !href }>
                    <p
                      block="Footer"
                      elem="ColumnItem"
                      mods={ src ? { type: 'image' } : undefined }
                      key={ i }
                      aria-label={ title }
                    >
                        { this.renderColumnItemContent(src, title) }
                    </p>
                </If>
            </>
        );
    }

    renderBanner(): ReactElement {
        return (
            <div block="Footer" elem="Banner">
                <div block="Container">
                    <div>
                        <FaCheck />
                        { __('Footer.banner.one') }
                    </div>
                    <div>
                        <FaCheck />
                        { __('Footer.banner.two') }
                    </div>
                    <div>
                        <FaCheck />
                        { __('Footer.banner.three') }
                    </div>

                </div>
            </div>
        );
    }

    render(): ReactElement {
        const { isVisibleOnMobile, device } = this.props;

        if (!isVisibleOnMobile && device.isMobile) {
            return null;
        }

        // if (isVisibleOnMobile && !device.isMobile) {
        //     return null;
        // }

        return (
            <RenderWhenVisible>
                <NewsletterSubscription key="NewsletterSubscription" />
                { /* { this.renderBanner() } */ }
                <footer block="Footer" aria-label="Footer">
                    { this.renderContent() }
                    { this.renderBottomFooter() }
                </footer>
            </RenderWhenVisible>
        );
    }
}

export default FooterComponent;
