import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import {
    mapDispatchToProps as sourceMapDispatchToProps,
    mapStateToProps as sourceMapStateToProps,
    NewsletterSubscriptionContainer as SourceNewsletterSubscriptionContainer,
} from 'SourceComponent/NewsletterSubscription/NewsletterSubscription.container';
import { Device } from 'Type/Device.type';
import { RootState } from 'Util/Store/Store.type';

import { NewsletterSubscriptionContainerProps } from './NewsletterSubscription.type';
/** @namespace Pwa/Component/NewsletterSubscription/Container/mapStateToProps */
export const mapStateToProps = (state: RootState) => ({
    ...sourceMapStateToProps(state),
    device: state.ConfigReducer.device,
});

/** @namespace Pwa/Component/NewsletterSubscription/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch: Dispatch) => ({
    ...sourceMapDispatchToProps(dispatch),
    // TODO extend mapDispatchToProps
});

/** @namespace Pwa/Component/NewsletterSubscription/Container */
export class NewsletterSubscriptionContainer extends SourceNewsletterSubscriptionContainer {
    props!: NewsletterSubscriptionContainerProps;

    containerProps(): { isLoading: boolean; device: Device } {
        const { isLoading } = this.state;
        const { device } = this.props;

        return { isLoading, device };
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(NewsletterSubscriptionContainer);
