import { connect } from 'react-redux';

import FooterCheckout from 'Component/FooterCheckout';
import {
    FooterContainer as SourceFooterContainer,
    mapDispatchToProps as sourceMapDispatchToProps,
    mapStateToProps as sourceMapStateToProps,
} from 'SourceComponent/Footer/Footer.container';
import { ReactElement } from 'Type/Common.type';
import { RootState } from 'Util/Store/Store.type';

import Footer from './Footer.component';
import { FooterComponentProps, FooterContainerProps, FooterContainerPropsKeys } from './Footer.type';

/** @namespace Pwa/Component/Footer/Container/mapStateToProps */
export const mapStateToProps = (state: RootState) => ({
    ...sourceMapStateToProps(state),
    isCheckout: state.CheckoutReducer.isCheckout,
    isMenu: state.CheckoutReducer.isMenu,
});

/** @namespace Pwa/Component/Footer/Container/mapDispatchToProps */
export const mapDispatchToProps = () => ({
    ...sourceMapDispatchToProps(),
});

/** @namespace Pwa/Component/Footer/Container */
export class FooterContainer extends SourceFooterContainer {
    static defaultProps: Partial<FooterContainerProps> = {
        copyright: '',
        isVisibleOnMobile: true,
    };

    props!: FooterContainerProps;

    containerProps(): Pick<FooterComponentProps, FooterContainerPropsKeys> {
        const {
            copyright,
            isVisibleOnMobile,
            device,
            newsletterActive,
        } = this.props;

        return {
            copyright,
            isVisibleOnMobile,
            device,
            newsletterActive,
        };
    }

    render(): ReactElement {
        const { isCheckout, copyright, isMenu } = this.props;

        if (isMenu) {
            return null;
        }

        if (isCheckout) {
            return (
                <FooterCheckout copyright={ copyright } />
            );
        }

        return (
            <Footer
              { ...this.containerProps() }
              { ...this.containerFunctions }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(FooterContainer);
