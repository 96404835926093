export const RENDER_NEWSLETTER = 'render_newsletter';
export const RENDER_FIRST_COLUMN = 'render_first_column';
export const RENDER_FOURTH_COLUMN = 'render_fourth_column';
export const RENDER_FIFTH_COLUMN = 'render_fifth_column';

export const NEWSLETTER_COLUMN = {
    title: 'Footer.newsletter',
    columnActiveKey: 'newsletterActive',
    items: [
        {
            render: RENDER_NEWSLETTER,
        },
    ],
};

export const FIRST_COLUMN = {
    items: [
        {
            render: RENDER_FIRST_COLUMN,
        },
    ],
};

export const FOURTH_COLUMN = {
    title: 'Footer.clientService',
    items: [
        {
            render: RENDER_FOURTH_COLUMN,
        },
    ],
};

export const FIFTH_COLUMN = {
    items: [
        {
            render: RENDER_FIFTH_COLUMN,
        },
    ],
};

export const COLUMN_MAP = [
    FIRST_COLUMN,
    {
        title: 'Motorparts',
        items: [
            {
                title: 'Footer.street',
            },
            {
                title: 'Footer.address',
            },
            {
                title: 'Footer.telephone',
            },
            {
                title: 'Footer.telephone2',
            },
            {
                title: 'Footer.fiscalCode',
            },
            {
                title: 'Footer.VATnumber',
            },
            {
                title: 'Footer.place',
            },
            {
                title: 'Footer.site',
            },
        ],
    },
    {
        title: 'Footer.informations',
        items: [
            {
                href: 'Footer.termsUrl',
                title: 'Footer.terms',
            },
            {
                href: 'Footer.deliveryUrl',
                title: 'Footer.delivery',
            },
            {
                href: 'Footer.returnedUrl',
                title: 'Footer.returned',
            },
            {
                href: 'Footer.privacyUrl',
                title: 'Footer.privacy',
            },
            // {
            //     href: 'Footer.cookiesUrl',
            //     title: 'Footer.cookies',
            // },
        ],
    },
    FOURTH_COLUMN,
    FIFTH_COLUMN,
];
