/* eslint-disable @typescript-eslint/naming-convention */
import { FieldType } from 'Component/Field/Field.config';
import { FieldContainerProps } from 'Component/Field/Field.type';
import { ValidationInputType } from 'Util/Validator/Config';

/**
 * Returns fields for newsletter
 * @returns {[{validateOn: [string], validationRule: {isRequired: boolean, inputType: string}, type: string, attr: {name: string, placeholder: *, 'aria-label': *}}]}
 * @namespace Pwa/Component/NewsletterSubscription/NewsletterForm/Form/newsletterSubscriptionForm */
export const newsletterSubscriptionForm = (): Partial<FieldContainerProps>[] => [
    {
        type: FieldType.EMAIL,
        attr: {
            name: 'newsletterEmail',
            placeholder: __('Newsletter.email'),
            'aria-label': __('Newsletter.email'),
        },
        validateOn: ['onChange'],
        validationRule: {
            inputType: ValidationInputType.EMAIL,
            isRequired: true,
        },
    },
    {
        type: FieldType.CHECKBOX,
        attr: {
            name: 'privacyCheck',
            id: 'privacyCheck',
            'aria-label': __('Newsletter.privacyCheck'),
            required: true,
        },
        validateOn: ['onChange'],
        validationRule: {
            isRequired: true,
        },
        label: __('Newsletter.privacyLabel'),
    },
];

export default newsletterSubscriptionForm;
